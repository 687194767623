import { ApiCore } from "./core";

export const apiLogin = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});
export const apiCompanyLogin = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiTable = (url: string) =>
	new ApiCore({
		getAll: true,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiGetSingle = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: true,
		postFormData: false,
		post: false,
		put: false,
		delete: false,
		url: url,
	});

export const apiGetAll = (url: string) =>
	new ApiCore({
		getAll: true,
		getSingle: false,
		postFormData: false,
		post: false,
		put: false,
		delete: false,
		url: url,
	});

export const apiLoadForm = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiAddTrans = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: true,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiDeleteTrans = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiLoadConstant = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiUpdateApproval = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiUpdateStatus = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});

export const apiLoadComment = (url: string) =>
	new ApiCore({
		getAll: false,
		getSingle: false,
		postFormData: false,
		post: true,
		put: false,
		delete: false,
		url: url,
	});
