import MainRoute from "./MainRoute";

function App() {

  return (
    <MainRoute />
  );
}

export default App;
