import Cookie from "universal-cookie";

const cookie = new Cookie();

const get = (key: string) => {
  return cookie.get(key);
};

const set = (key: string, value: string, option?: Object, expires?: Date) => {
  if(expires){
    cookie.set(key, value, {path: "/", expires: expires});
  }else{
    cookie.set(key, value, {path: "/"});
  }
};

const remove = (key: string) => {
  cookie.remove(key);
};

const removeAll = () => {

};

export const CookieService = {
  get,
  set,
  remove,
  removeAll,
};
