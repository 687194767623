import React, { useState, lazy, Suspense } from "react";
import {
    BrowserRouter as Router,
    Navigate
} from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermAndConditions } from "./pages/TermAndConditions";
import Layout from "./layouts/Layout";
import InvoiceNow from "./pages/InvoiceNow";
import { WaitingNew } from "./pages/WaitingNew";
import UnlockBusinessEfficiency from "./pages/UnlockBusinessEfficiency";
import {Unauthorization} from "./pages/Unauthorization";
import {Pricing} from "./pages/Pricing";
import Contact from "./pages/Contact";
import { ContactUs } from "./pages/ContactUs";

function MainRoute() {

    window.onpopstate = function () {
        window.history.go(-1);
    };

    const [baseName, setBaseName] = useState(process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : "");
    const mainPage = process.env.REACT_APP_MAIN_PAGE ? process.env.REACT_APP_MAIN_PAGE : "";
    return (
        <Router basename={baseName}>
            <Routes>
                {/* <Route element={<Layout />}> */}
                {/* {mainPage == "" && ( */}
                <Route path="/"
                       element={<Suspense fallback={<div>Loading...</div>}><UnlockBusinessEfficiency/></Suspense>}/>
                {/* )} */}
                {/* {mainPage == "tnc" && ( */}
                <Route path="/terms-conditions"
                       element={<Suspense fallback={<div>Loading...</div>}><TermAndConditions/></Suspense>}/>
                {/* )} */}
                {/* {mainPage == "pp" && ( */}
                <Route path="/privacy-policy"
                       element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy/></Suspense>}/>
                <Route path="/contact"
                       element={<Suspense fallback={<div>Loading...</div>}><ContactUs/></Suspense>}/>
                {/* )} */}
                {/* </Route> */}
                <Route path="/invoicenow"
                       element={<Suspense fallback={<div>Loading...</div>}><InvoiceNow/></Suspense>}/>
                <Route path={"/timing"} element={<Suspense fallback={<div>Loading...</div>}><WaitingNew/></Suspense>}/>
                <Route path={"/404"}
                       element={<Suspense fallback={<div>Loading...</div>}><Unauthorization/></Suspense>}/>
                <Route path={"/pricing"} element={<Suspense fallback={<div>Loading...</div>}><Pricing/></Suspense>}/>
                <Route path={"/contact/:subUrl"} element={<Suspense fallback={<div>Loading...</div>}><Contact/></Suspense>}/>
            </Routes>
        </Router>
    );
}

export default MainRoute;
