import { colors } from "../../constants/colourConfigurations";
import "./loader.css";
import Logo from "../../assets/AgileAp-Logo-icon.png";

interface LoaderProps {
    visibility: boolean;
    colour?: string;
    backdrop?: boolean;
    zIndex?: boolean;
    loader: boolean;
    zIndexLevel?: number;
    customClass?: string;
    backdropBlurClass?: string;
}

export const LoaderV2 = ({
                           visibility,
                           colour = colors.tertiary,
                           backdrop,
                           zIndex,
                           loader,
                           zIndexLevel,
                           customClass,
                           backdropBlurClass,
                       }: LoaderProps) => {
    return (
        <div
            className={[
                visibility ? "show" : "",
                backdrop ? "h-screen backdrop backdrop-grayscale" : customClass,
                zIndex ? (zIndexLevel ? `z-[${zIndexLevel}]` : "z-[40]") : "",
                backdrop ? (backdropBlurClass ? backdropBlurClass : "backdrop-blur-md") : "",
            ].join(" ")}
        >
            {/* <div className="spinner-container">
        <div id={loader && visibility ?"loadingScreenShow":""} className={`loading-spinner ${loader && "show"}`} ></div>
      </div> */}
            <div
                id={loader && visibility ? "loadingScreenShow" : ""}
                className={`loading-container ${loader ? "visible" : "hidden"}`}
            >
                <img src={Logo} alt="Loading" className="loading-logo" />
            </div>
        </div>
    );
};
