import { useCallback, useEffect, useState } from "react";
import { BiReset } from "react-icons/bi";
import { GrSearchAdvanced } from "react-icons/gr";

interface TextAreaProps {
  /**
   * id of field
   */
  id: string;
  /**
   * Name of field
   */
  name: string;
  /**
   * Field label
   */
  label: string;
  /**
   * Required field
   */
  required: boolean;
  /**
   * onChange callback
   */
  onChange: any;
  /**
   * Error flag for display style
   */
  error: boolean;
  /**
   * Helper text for eror message
   */
  helperText: string;
  /**
   * Readonly flag
   */
  readonly: boolean;
  /**
   * Grid size (xs)
   */
  xs: number;
  /**
   * Grid size (sm)
   */
  sm: number;
  /**
   * Disabled flag
   */
  disabled?: boolean;
  /**
   * Value for controlled input
   */
  value: string;
  /**
   * Number of rows for multiline input display
   */
  numberOfRows: number;
  returnLineBreak?: (lineBreak: number) => void;
  customBg?: string;
  customLabelClass?: string;
  backgroundColor?: string;
  resetAddress?: boolean;
  maxHeight?: string;
  showProductMenu?: boolean;
  callProductMenu?: (formFieldId: string) => void;
  formFieldId?: string;
}

export const TextArea = (input: TextAreaProps) => {

  const autoExpand = (elements: any) => {
    // console.log(elements, "LOADLOAD");
    const inter = setInterval(() => {
      if (elements) {
        clearInterval(inter);
        elements.forEach((element: any) => {
          if (input.maxHeight && input.maxHeight.length > 0) {
            element.style.maxHeight = input.maxHeight;
          }
          element.style.height = "auto";
          element.style.height = `${element.scrollHeight + 2}px`;

          // console.log(elements, input.maxHeight, element.scrollHeight, input.numberOfRows, "LOADLOAD");
        });
      }
    }, 100);
    return () => {
      clearInterval(inter);
    };
  };

  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      // console.log("LOADLOAD",document.querySelectorAll(`.textarea-class${node.id}`))
      // autoExpand(node.id);
      autoExpand(document.querySelectorAll(`.textarea-class${node.id}`));
      // Do what you want to do when the size of the element changes
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    autoExpand(document.querySelectorAll(`.textarea-class${input.id}`));
  }, [input.value]);

  const lineBreaks = (input.value?.match(/\n/g) || []).length + 1;
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [openConfirm, setOpenConfirm] = useState(false);
  const textAreaInput = (
    <>
      <div className="flex flex-col w-full">
        {input.label && (
          // <>
          <label
            className={[
              "form-label text-xs md:text-sm lg:text-base font-medium flex gap-1 mb-1",
              input.error ? "text-red-500 text-xs md:text-sm" : "",
              !input.error
                ? input.readonly
                  ? "text-workflowContent text-sm md:text-base"
                  : input.customLabelClass
                  ? input.customLabelClass
                  : "text-readOnlyText text-xs md:text-sm"
                : "",
            ].join(" ")}
          >
            {input.label}
           
          </label>
          // </>
        )}
        {!input.readonly ? (
          <div className="relative -mb-[7px]">
            {input.showProductMenu && !input.readonly && (
              <div className="absolute right-2 top-2 text-xl md:text-2xl">
                <GrSearchAdvanced
                  onClick={() => input.callProductMenu?.(input.formFieldId !== undefined ? input.formFieldId : "")}
                  className="hover:cursor-pointer hover:text-gray-500 duration-200"
                />
              </div>
            )}
            <textarea
              ref={elementRef}
              className={[
                `${"textarea-class" + input.id}`,
                "w-full text-xs md:text-sm min-h-[40px] focus:outline-none p-4 h-fit rounded-lg border focus:border-inputFocusRing focus:ring-1 focus:ring-inputFocusRing",
                input.error ? "border-red-500 shadow-xl" : "",
                input.readonly
                  ? "border-none text-readOnlyText font-semibold cursor-default"
                  : "border-gray-300 text-gray-900",
                input.showProductMenu ? "pr-8" : ""
              ].join(" ")}
              defaultValue={input.value}
              value={input.value}
              onChange={(e: any) => {
                if (e.target.value && e.target.value.length > 5000) {
                  
                } else {
                  input.onChange(e);
                  autoExpand(document.querySelectorAll(`.textarea-class${input.id}`));
                  if (input.returnLineBreak) input.returnLineBreak(lineBreaks);
                }
              }}
              readOnly={input.readonly}
              disabled={input.disabled}
              name={input.name}
              id={input.id}
              rows={input.numberOfRows}
              required
            />
            {input.helperText && (
              <label className={`pt-1 text-xs font-light ${input.error ? "text-warning" : ""}`}>
                {input.helperText}
              </label>
            )}
            {
                input.value && input.value.length > 5000 &&
                <label className={`pt-1 text-xs font-light ${input.error ? "text-warning" : ""}`}>{"Your note exceeds the 5000 characters."}</label>
            }
          </div>
        ) : (
          <span
            className={`w-full h-10 border-gray-300 border rounded-lg ${
              input.readonly ? "text-sm md:text-base" : "text-xs md:text-sm"
            } text-readOnlyText font-semibold cursor-default`}
          >
            {input.value}
          </span>
        )}
      </div>
    </>
  );

  const readOnlyTextAreaInput = (
    <>
      <div className="flex flex-col">
        <label
          className={[
            "form-label font-normal text-xs md:text-sm",
            input.error ? "text-red-900" : "",
            input.readonly ? "text-quaternaryFont" : "text-readOnlyText",
            input.customLabelClass ? input.customLabelClass : "",
          ].join(" ")}
        >
          {input.label}
        </label>
        <div className={`flex items-center w-full text-readOnlyText font-bold whitespace-pre-wrap cursor-default text-xs md:text-sm lg:text-base`}>{input.value && input.value != "" ? input.value : "-"}</div>
      </div>
    </>
  );

  return <>{input.readonly ? readOnlyTextAreaInput : textAreaInput}</>;
};
