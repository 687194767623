import Footer from "../components/Footer";
import { EmptyWallet, DocumentText, TickCircle, CrossCircle, Cards, DashboardLogo } from "../assets/svg";
import { useEffect, useState } from "react";
import ToogleTab from "../components/ToogleTab";
import { HomeLayout } from "../layouts/HomeLayout";
import Mark2 from "../assets/image/AgileAp-Logo-icon-mark 2.png";
import Mark1 from "../assets/image/AgileAp-Logo-icon-mark 1.png";
import Content from "../assets/image/Content.png";
// import Dashboard from '../assets/image/Dashboard.png';
import { apiLogin } from "../services";
import { TextValue } from "../translations";
import CountdownV2 from "../components/CountDownV2";
import { Loader } from "../components/Loader";
import { colors } from "../constants/colourConfigurations";
import * as React from "react";
import { Icon, IconType } from "../components/Icon";
import { isMobile } from "react-device-detect";
import SecSectionBg from "../assets/image/SecSectionBg.png";
import FirSectionBg from "../assets/image/FirSectionBg.png";
import MetatagHelmet from "../components/MetatagHelmet";
import dashboard1 from "../assets/image/Dashboard-Landing-1.png";
import dashboard2 from "../assets/image/Dashboard-Landing-2.png";
import promotion from "../assets/image/AgileAP_Promo.gif";
import greet from "../assets/image/greet_3.png";

const imageURLHost = process.env.REACT_APP_IMAGE_URL;
export default function UnlockBusinessEfficiency() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const tabHeaders = ["With AgileAp", "Without AgileAp"];
  const emailAddress = "info@agileap.com";
  const subject = "Inquiry About AgileAP";
  const body =
    "Hello,\n\nI am interested in the AgileAP. I would like to request for a demo. \nInfomation: \nTitle: \nFull Name: \nCompany Name: \nEmail: ";
  // for timing
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setIsLoading] = useState(false);
  const [selPayableIndex, setSelPayableIndex] = useState(0);
  const [selReceivableIndex, setReceivableIndex] = useState(0);
  const payableList = [
    {
      name: "Purchase Requisition",
      description:
        "Optimized the creation, approval, and tracking of purchase requests, improving efficiency, reducing errors, ensuring compliance with procurement policies, and providing real-time visibility into spending",
      image: "img/PR.png",
    },
    {
      name: "Purchase Order",
      description:
        "Streamlines purchase order management, ensuring accurate transaction recording, efficient approvals, seamless integration with inventory and invoice systems, and visibility into different status of the purchase orders for better financial control and reporting.",
      image: "img/PO.png",
    },
    {
      name: "Goods Receipt",
      description:
        "Involves verifying the receipt of ordered goods or services, ensuring they meet quality standards, recording them accurately, and flipping purchase orders to goods receipt notes (GRN) to facilitate timely payment processing and inventory management.",
      image: "img/GRN.png",
    },
    {
      name: "Invoice Received",
      description:
        "Simplifies and automates the processing of incoming invoices, ensuring quick validation, accurate recording, ability to upload PDF invoices received and seamless integration with InvoiceNow, for improved efficiency and financial control",
      image: "img/IR.png",
    },
    {
      name: "Claims",
      description:
        "Facilitates efficient processing and management of expense claims submitted by employees, ensuring timely reimbursement, compliance with company policies, accurate recording of expenses, and visibility of transaction limits per claim types that is tied to employee benefits for financial reporting purposes.",
      image: "img/Claims.png",
    },
    {
      name: "Payment",
      description:
        "Expedites vendor payment processing, enhances financial transparency, improves cash management through automated transaction handling, comprehensive reporting capabilities, and the ability to import and export payments to and from other payment systems.",
      image: "img/Payments.png",
    },
    {
      name: "Supplier Management",
      description:
        "Enhances vendor interactions by providing a centralized platform for communication, document sharing, order tracking, real-time updates, and the ability to invite vendors to join your ecosystem, thereby improving procurement processes and fostering stronger supplier relationships.",
      image: "img/SupplierManagement.png",
    },
    {
      name: "Reports",
      description:
        "Provides comprehensive insights into the financial status of a business, detailing outstanding invoices, pending payments, aging of receivables and payables, cash flow projections, and other key metrics essential for informed decision-making and financial management.",
      image: "img/Reports.png",
    },
  ];
  const receiveableList = [
    {
      name: "Quotation",
      description:
        "Facilitates the creation, customization, and tracking of quotes for potential sales transactions, enabling efficient communication with customers and assisting in converting leads into revenue.",
      image: "img/Quotation.png",
    },
    {
      name: "PO Received",
      description:
        "Enables businesses to efficiently process and record incoming purchase orders from customers, ensuring accurate tracking of sales transactions and timely fulfillment of orders and seamless integration with InvoiceNow.",
      image: "img/POReceived.png",
    },
    {
      name: "Delivery Order",
      description:
        "Facilitates streamlined management of order fulfillment processes, providing visibility into shipment status, enhancing customer service, and ensuring timely revenue recognition.",
      image: "img/DO.png",
    },
    {
      name: "Invoice",
      description:
        "Streamlines invoicing processes, enhances billing accuracy, expedites payment collection, and provides comprehensive reporting capabilities for improved financial management.",
      image: "img/Invoice.png",
    },
    {
      name: "Credit Note",
      description:
        "Simplify the issuance and management of credit notes to customers for returned goods or services, adjustments, or discounts, ensuring accurate recording of transactions, timely resolution of credit-related issues, and the ability to flip invoices to credit notes.",
      image: "img/CN.png",
    },
    {
      name: "Customer Management",
      description:
        "Streamlines customer interactions by providing a centralized platform for managing customer information, tracking communications, monitoring account statuses, facilitating efficient billing and collections processes, and the ability to add customers through searching them in the Peppol network.",
      image: "img/CustomerManagement.png",
    },
  ];

  useEffect(() => {
    if (window.innerWidth > 768 && window.innerWidth < 1280) {
      if (payableList[selPayableIndex] != null && payableList[selPayableIndex] != undefined) {
        var getEle = document.getElementById("AP" + selPayableIndex);
        var getImg = document.getElementById("APIMG" + selPayableIndex);
        var apOptionHead = document.getElementById("apOptionHead");
        if (getEle && getImg && apOptionHead) {
          const rect = getEle.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const distanceFromTop = rect.top + scrollTop;

          const rectOpt = apOptionHead.getBoundingClientRect();
          const scrollTopOpt = window.pageYOffset || document.documentElement.scrollTop;
          const distanceFromTopOpt = rectOpt.top + scrollTopOpt;

          const neededDistance = distanceFromTop - distanceFromTopOpt;
          getImg.style.paddingTop = neededDistance + "px";
          console.log(
            getEle?.clientHeight,
            getEle?.offsetHeight,
            getEle?.clientTop,
            distanceFromTop,
            distanceFromTopOpt
          );
        }
      }
    } else {
      var getImg = document.getElementById("APIMG" + selPayableIndex);
      if (getImg) {
        getImg.style.paddingTop = "0px";
      }
    }
  }, [selPayableIndex]);

  useEffect(() => {
    if (window.innerWidth > 768 && window.innerWidth < 1280) {
      if (receiveableList[selReceivableIndex] != null && receiveableList[selReceivableIndex] != undefined) {
        var getEle = document.getElementById("AR" + selReceivableIndex);
        var getImg = document.getElementById("ARIMG" + selReceivableIndex);
        var apOptionHead = document.getElementById("arOptionHead");
        if (getEle && getImg && apOptionHead) {
          const rect = getEle.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const distanceFromTop = rect.top + scrollTop;

          const rectOpt = apOptionHead.getBoundingClientRect();
          const scrollTopOpt = window.pageYOffset || document.documentElement.scrollTop;
          const distanceFromTopOpt = rectOpt.top + scrollTopOpt;

          const neededDistance = distanceFromTop - distanceFromTopOpt;
          getImg.style.paddingTop = neededDistance + "px";
          console.log(
            getEle?.clientHeight,
            getEle?.offsetHeight,
            getEle?.clientTop,
            distanceFromTop,
            distanceFromTopOpt
          );
        }
      }
    } else {
      var getImg = document.getElementById("ARIMG" + selReceivableIndex);
      if (getImg) {
        getImg.style.paddingTop = "0px";
      }
    }
  }, [selReceivableIndex]);

  const handleGetDate = () => {
    setIsLoading(true);
    apiLogin("constant/check-maintenance")
      .post({ id: null, authenticated: false, body: { ID: 0 } })
      .then((resp) => {
        console.log("response data : ", resp.data);
        if (resp.data && resp.data.result !== null) {
          let info = resp.data.result;
          setEndDate(new Date(info.dtEnd));
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  useState(() => {
    handleGetDate();
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const handleCallBackForTiming = () => {
    setCurrentDate(new Date());
  };

  const isDateBeforeOrOn = (date1: Date, date2: Date): boolean => {
    // Set time to 00:00:00 to only compare the date part
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    // Return true if date1 is before or the same as date2
    return date1.getTime() <= date2.getTime();
  };

  const targetDate = new Date("2024-08-31");

  const mainContentDesktopForTiming = (
    <div className={`p-10 h-full w-full`}>
      {!loading && (
        <>
          <div className={`text-3xl sm:text-2xl lg:text-5xl pt-20 text-center text-primary font-bold`}>
            {TextValue("SITE UNDER MAINTENANCE")}
          </div>
          <div className={`text-xl font-medium py-10 text-center`}>
            {TextValue("Sorry for the inconvenience. To improve our service, we have momentarily shutdown our site.")}
          </div>
          <div className={`flex justify-center gap-4`}>
            <div
              className={`p-10 lg:w-2/5 md:w-1/2 sm:w-full bg-blue-500 text-white font-bold text-2xl md:text-4xl sm:text-3xl lg:text-5xl rounded-xl`}
            >
              <CountdownV2 handleCallBack={handleCallBackForTiming} targetDate={endDate} />
            </div>
          </div>
        </>
      )}
      {loading && (
        <div className={`my-[25%] flex justify-center content-center`}>
          <Loader size={75} className={"flex"} visibility={true} colour={colors.secondary} loadingText={""} />
        </div>
      )}
      {/*<div className={`flex flex-wrap w-full justify-center items-center gap-6 my-10`}>
                <div className={`w-1/4`}>
                    <input type={"email"} placeholder={TextValue("Enter email address")} className={`ring-0 w-full px-4 py-2 rounded-lg border border-solid border-gray-400 text-base`}/>
                </div>
                <div>
                    <button
                        type="button"
                        className="font-medium text-white bg-primary rounded-lg hover:ring-0 hover:outline-none hover:bg-opacity-70 active:bg-opacity-50 font-medium rounded-xl text-sm px-4 py-2 text-center mr-3 md:mr-0 ">
                        Get Notified!
                    </button>
                </div>
            </div>*/}
    </div>
  );
  //-------------
  const handleClick = () => {
    // if (isMobile) {
    // const phoneNumber = "+65 6684 7044";
    // window.location.href = `tel:${phoneNumber}`;
    window.open(`https://wa.me/6580880170/?text=${encodeURIComponent(body)}`, "", "noreferrer");
    // } else {
    //   const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    //     body
    //   )}`;
    //   window.location.href = mailtoLink;
    // }
  };

  const mainContentDesktop = (
    <>
      <MetatagHelmet
        title="AgileAP - Automate Accounts Payable and Receivables | Singapore InvoiceNow Compliant"
        description="Automate your Accounts Payable and Receivable processes with AgileAP. Comply with Singapore's InvoiceNow e-invoicing requirements, enhance governance with customizable workflows, role-based access rights, 3-way matching, intelligent analytics, and dashboards. Manage suppliers, customers, quotations, purchase requests, orders, invoices, claims, and timesheet efficiently with AgileAP."
        url={window.location.href}
      />
      <div className="flex flex-col w-full items-center ">
        {isDateBeforeOrOn(currentDate, targetDate) && (
          <section className="flex flex-col w-full space-y-12 items-center" id="promotion">
            {/* <img src={FirSectionBg} className="w-full absolute top-0 z-10" /> */}
            {/* <img src={Mark1} className="absolute w-44 w-full top-0 right-0" /> */}
            <div
              className="relative xl:pt-20 pt-10 w-full bg-cover bg-left-bottom min-h-[91vh] md:min-h-[93vh]"
              style={{ backgroundImage: `url(${promotion})` }}
            >
              <div className="font-inter flex flex-col justify-center mx-auto w-lgMain text-center gap-6 w-lgMain z-10">
                <div className="font-inter font-bold px-8 py-2 bg-[#DD5561] border-[#CA3B49] text-center text-white mx-auto w-fit rounded-md text-xs md:text-base">
                  LIMITED-TIME OFFER
                </div>
                <div className="flex-col justify-center">
                <label className="block pt-6 font-rubik text-center font-bold text-white leading-[60px] text-4xl sm:text-5xl md:text-6xl xl:text-8xl">
                  Happy 59<sup>th</sup> Birthday
                </label>
                <label className="block sm:pt-4 font-rubik text-center font-bold text-white leading-[60px] text-4xl sm:text-5xl md:text-6xl xl:text-8xl">
                  Singapore
                  <span className="inline-flex items-baseline pl-1 md:pl-4">
                  <img src={greet} className="w-[40px] sm:w-[50px] md:w-[80px] xl:w-[100px]"/>
                  </span>
                </label>
                </div>
                <label className="font-inter w-fit py-2 px-4 mx-auto rounded-lg mt-3 text-xl md:text-3xl xl:text-4xl font-bold text-white bg-[#DD5561]/[0.6] ">
                  {TextValue("Celebrate with 59% Off on a 1-Year Subscription!")}
                  <br />
                  {TextValue("Promo ends on 31 August 2024")}
                </label>
                <div className="font-inter font-bold text-xl md:text-3xl mt-4 mb-8 px-10 py-6 bg-[#E6E9F5]/[0.8] border-[#E6E9F5] text-center text-[#DD3A61] mx-auto w-fit rounded-md">
                  PROMO CODE: SG59
                </div>
                {/* <DashboardLogo className="z-10" /> */}
                {/* <img src={dashboard1} /> */}
              </div>
            </div>
            {/* <img src={Mark2} className="w-32 lg:top-[22rem] top-[19rem] lg:bottom-0 lg:w-1/5 absolute left-0" /> */}
          </section>
        )}
        <section className="flex flex-col w-full space-y-12 items-center" id="home">
          {/* <img src={FirSectionBg} className="w-full absolute top-0 z-10" /> */}
          {/* <img src={Mark1} className="absolute w-44 lg:w-1/5 top-20 right-0" /> */}
          <div
            className="relative md:pt-40 pt-10 w-full md:bg-cover bg-auto"
            style={{ backgroundImage: `url(${FirSectionBg})` }}
          >
            <div className="font-inter flex flex-col mx-auto w-lgMain text-center gap-6 w-lgMain z-10">
              <label className="block text-center font-bold leading-tight text-4xl lg:text-7xl">
                {TextValue("Unlock Business Efficiency ")}
              </label>
              <label className="text-xl font-normal text-grayLight">
                {TextValue(
                  "Empower Your Business with Comprehensive Accounts Payable and Accounts Receivable Automation"
                )}
              </label>
              <div className="flex gap-2 justify-center">
                <button
                  onClick={() => {
                    handleClick();
                  }}
                  type="button"
                  className="font-bold text-base md:text-xl text-white bg-primary focus:ring-0 focus:outline-none hover:bg-opacity-70 active:bg-opacity-50 rounded-lg text-sm px-4 py-3 md:px-8 md:py-5 text-center mr-3 md:mr-0 "
                >
                  {TextValue("Request a Demo")}
                </button>
                <button
                  onClick={() => {
                    window.location.href = "/pricing";
                  }}
                  type="button"
                  className="font-bold text-base md:text-xl text-primary bg-primary bg-opacity-20 focus:ring-0 focus:outline-none hover:bg-opacity-40 hover:text-white active:bg-opacity-40 active:text-white rounded-lg text-sm px-4 py-3 md:px-8 md:py-5 text-center mr-3 md:mr-0 "
                >
                  {TextValue("Sign Up Now")}
                </button>
              </div>
              {/* <DashboardLogo className="z-10" /> */}
              <img src={dashboard1} />
            </div>
          </div>
          {/* <img src={Mark2} className="w-32 lg:top-[22rem] top-[19rem] lg:bottom-0 lg:w-1/5 absolute left-0" /> */}
        </section>
        <section className="flex flex-col w-full pb-12 space-y-12 items-center" id="ourwork">
          <div className="relative pt-10">
            {/* <div className="relative pt-10 bg-cover" style={{ backgroundImage: `url(${SecSectionBg})` }}> */}
            {/* <img src={SecSectionBg} className="w-full absolute top-0" /> */}
            <div className="flex flex-col mx-auto py-10 px-5 lg:w-5/6 xl:w-2/3 text-center space-y-6 relative">
              <label className="font-black text-[#1C2452] text-2xl md:text-4xl lg:text-5xl px-4 lg:px-0">
                {TextValue("Simplify Your AP and AR Processes")}
              </label>
              <p className="font-light text-base px-4 md:text-lg text-[#1C2452]">
                {TextValue(
                  "At AgileAP, we understand the importance of efficient financial management for your business. Our platform offers a comprehensive suite of tools designed to streamline your accounts payable processes and elevate your financial operations. Here's why our platform is the ideal choice for managing your business finances"
                )}
              </p>
            </div>
            <div className="flex justify-center md:flex-row flex-col gap-2 lg:gap-8 w-full flex-row px-2 md:px-10 lg:px-20 lg:pt-5 p-2 md:pb-10">
              <video
                src={imageURLHost + "video/agileap_presentation_v_4_2.mp4"}
                className="w-full md:w-1/2 shadow-xl rounded-lg border"
                controls
                poster={imageURLHost + "img/AgileApLogo_Tagline.jpg"}
              />
              <img src={dashboard2} className="w-full md:w-1/2 shadow-xl border rounded-lg" />
            </div>
          </div>
          <div className="w-lgMain">
            <label className="block text-left font-black leading-tight text-2xl lg:text-3xl py-5">
              {TextValue("Our Services")}
            </label>
            <div className="flex lg:flex-row xs:flex-col md:flex-col flex-col items-center gap-y-8 lg:items-start w-full gap-x-32 pb-8">
              <div className="flex flex-col lg:w-1/2 space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.Receipt} fill="#F15A29" />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl ">
                      {TextValue("Automated Invoice Receipts")}
                    </label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "Say goodbye to the tedious and error-prone task of manually processing invoices as AgileAP seamlessly automates the receipt, processing, and approval of invoices. By leveraging advanced automation technology, AgileAP streamlines the entire invoicing process, resulting in significant cost savings in invoice processing costs. Experience unparalleled efficiency and accuracy as AgileAP revolutionizes your invoice management workflow, allowing you to focus on strategic initiatives and business growth while leaving the hassle of manual processing behind."
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex lg:w-1/2 flex-col space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.BullsEyePointer} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl">
                      {TextValue("Insights Through Analytics")}
                    </label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "Unlock the power of data-driven decision-making with AgileAP's intelligent analytics feature. By leveraging advanced analytics tools, AgileAP empowers you to gain valuable insights into various aspects of your business operations. From invoicing trends and transaction histories to client payment behaviors and financial performance metrics, AgileAP's analytics capabilities provide a comprehensive view of your business landscape."
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row xs:flex-col md:flex-col flex-col items-center gap-y-8 lg:items-start w-full gap-x-32 pb-8">
              <div className="flex flex-col lg:w-1/2 space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.FileInvDollar} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl ">{TextValue("Clear Payment History")}</label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "With AgileAP, tracking your business expenses is made effortless and precise. Our platform offers a comprehensive payment history feature, providing you with a clear and detailed overview of your expenditures on specific dates. By accessing this information, you can monitor your financial transactions with clarity and precision, enabling you to make well-informed decisions about your finances. Whether you need to review past payments, analyze spending patterns, or plan for future expenses, AgileAP empowers you with the insights you need to manage your finances effectively and optimize your business operations."
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex lg:w-1/2 flex-col space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.Analytics} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl">
                      {TextValue("Improved Vendor Relationships")}
                    </label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "AgileAP not only enhances your internal processes but also strengthens your vendor relationships. By offering self-service capabilities to your vendors, AgileAP empowers them to manage their invoices efficiently, reducing costs, labor, and delays typically associated with traditional invoice processing methods. This seamless interaction fosters trust and collaboration, ensuring timely payments and promoting positive relationships with your vendors. With AgileAP, you can streamline your vendor management processes, minimize friction in your supplier interactions, and cultivate strong, mutually beneficial partnerships that drive success for your business."
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row xs:flex-col md:flex-col flex-col items-center gap-y-8 lg:items-start w-full gap-x-32 ">
              <div className="flex flex-col lg:w-1/2 space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.Atom} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl ">{TextValue("Streamlined AP Workflows")}</label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "Transform your accounts payable processes into a dynamic and efficient engine with AgileAP's streamlined workflows. Our innovative solution empowers you to accelerate the approval cycle for invoices, driving significant cost reductions while ensuring compliance and governance. With AgileAP, custom workflows can be effortlessly created on-the-fly, tailored to your organization's specific needs. Experience unparalleled efficiency and control over your operations with AgileAP's intuitive workflow design tools, paving the way for improved productivity and cost savings."
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex lg:w-1/2 flex-col space-y-8">
                <div className="flex flex-col gap-1">
                  <div>
                    <Icon type={IconType.LaptopCode} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <label className="font-semibold text-lg lg:text-xl">
                      {TextValue("InvoiceNow Accredited Platform")}
                    </label>
                    <label className="text-sm font-normal text-secondary">
                      {TextValue(
                        "AgileAP is a solution provider accredited by the Infocomm Media Development Authority (IMDA) in Singapore. This accreditation signifies that AgileAP has met the stringent standards set by IMDA for electronic invoicing solutions. As an IMDA accredited solution provider, AgileAP has demonstrated its commitment to providing high-quality and reliable services in the field of electronic invoicing."
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full flex justify-center">
          <div className="flex flex-col w-full py-12 w-lgMain lg:w-[70vw] space-y-12 items-center" id="ourwork">
            <div className="flex flex-col gap-y-2 text-center">
              <label className="font-black text-3xl lg:text-4xl">{TextValue("Save valuable time on payments")}</label>
              <label>
                <span className="font-black text-3xl lg:text-4xl">{TextValue("with Agile")}</span>
                <span className="font-black text-3xl text-primary lg:text-4xl">{TextValue("AP")} </span>
                <span className="font-black text-3xl lg:text-4xl">{TextValue("automation.")}</span>
              </label>
            </div>
            <div className="md:flex gap-x-6">
              <div className="flex flex-col lg:w-2/5 w-full gap-y-4">
                <div className="flex flex-col gap-y-4">
                  <label className="text-lg font-semibold">Accounts Payable</label>
                  <p className="text-sm font-normal text-secondary" id="apOptionHead">
                    Effortlessly schedule payments and streamline your finances with automatic payments, eliminating the
                    need for manual processing.
                  </p>
                </div>
                <div className="flex flex-col">
                  <div className="mb-4 ">
                    <ul className="flex flex-col border-l border-gray-200 flex-wrap -mb-px text-sm font-medium ">
                      {payableList.map((item, index) => (
                        <div className="flex flex-col" id={"AP" + index}>
                          <li className="me-2" role="presentation">
                            <button
                              onClick={() => setSelPayableIndex(index)}
                              className={`text-md font-semibold inline-block px-4 py-2 ${
                                selPayableIndex === index ? "text-black border-l-4 border-primary" : ""
                              } hover:text-black hover:border-black`}
                            >
                              {item.name}
                            </button>
                          </li>
                          {selPayableIndex === index && (
                            <div className=" px-4 py-2">
                              <p className="text-sm font-thin text-grayBLue">{item.description}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex lg:w-3/5 w-full" id={"APIMG" + selPayableIndex}>
                {payableList.map((item, index) => (
                  <>
                    {selPayableIndex === index && (
                      <img
                        src={imageURLHost + item.image}
                        className="w-full  max-h-[350px] md:max-h-[450px] object-contain"
                      />
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lighterBlue w-full flex justify-center">
          <div className="flex flex-col w-full py-12 w-lgMain lg:w-[70vw] space-y-12 items-center" id="ourwork">
            <div className="flex gap-x-10 flex-col-reverse md:flex-row bg-grayBlue">
              <div className="flex lg:w-3/5 w-full" id={"ARIMG" + selReceivableIndex}>
                {receiveableList.map((item, index) => (
                  <>
                    {selReceivableIndex === index && (
                      <img
                        src={imageURLHost + item.image}
                        className="w-full max-h-[350px] md:max-h-[450px] object-contain"
                      />
                    )}
                  </>
                ))}
              </div>
              <div className="flex flex-col lg:w-2/5 w-full gap-y-4">
                <div className="flex flex-col gap-y-4">
                  <label className="text-lg font-semibold">Account Receivables</label>
                  <p className="text-sm font-normal text-secondary" id="arOptionHead">
                    Automate your invoicing process for quicker, hassle-free payment collection, eliminating the need
                    for manual tracking.
                  </p>
                </div>
                <div className="flex flex-col">
                  <div className="mb-4 ">
                    <ul className="flex flex-col border-l border-gray-200 flex-wrap -mb-px text-sm font-medium ">
                      {receiveableList.map((item, index) => (
                        <div className="flex flex-col" id={"AR" + index}>
                          <li className="me-2" role="presentation">
                            <button
                              onClick={() => setReceivableIndex(index)}
                              className={`text-md font-semibold inline-block px-4 py-2 ${
                                selReceivableIndex === index ? "text-black border-l-4 border-primary" : ""
                              } hover:text-black hover:border-primary`}
                            >
                              {item.name}
                            </button>
                          </li>
                          {selReceivableIndex === index && (
                            <div className=" px-4 py-2">
                              <p className="text-sm font-thin text-grayBLue">{item.description}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col w-full py-12 w-lgMain lg:w-[70vw] space-y-12 items-center" id="ourwork">
          <div className="flex flex-col gap-y-2 text-center">
            <label className="font-black text-3xl lg:text-4xl">
              {TextValue("Experience Efficiency, Elevate Productivity")}
            </label>
            <label className="text-secondary text-md lg:text-lg">
              {TextValue(
                "Transform your accounts payable and accounts receivable processes with AgileAP. Schedule a demo today and take the first step towards unlocking your business's full potential."
              )}
            </label>
          </div>
        </section>
        {/* <section className='flex flex-col w-full py-12 w-lgMain space-y-12 items-center'
                id="pricing">
                <div className='flex flex-col gap-y-2 text-center'>
                    <label className='font-black text-3xl lg:text-4xl'>{TextValue("Your Complete Solution")}</label>
                    <label
                        className='text-secondary text-md lg:text-lg'>{TextValue("AgileAP offers a comprehensive suite of features to meet all your accounts payable and account receivable needs.")}</label>
                </div>
                <div className={`my-10`}>
                    <label className='text-secondary text-xl lg:text-2xl'>
                        {TextValue("Account Payable")}
                    </label>
                </div>
                <div className='grid lg:grid-cols-4 grid-cols-2 md:grid-cols-3 w-full px-12 gap-8 justify-items-center'>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.InvoiceReceived} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Invoice Received")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.PurchaseOrder} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Purchase Order")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Claims} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Claims")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Payment} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Payment")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.StatementOfAccount} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Statement Of Account")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.GoodsReceipt} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Goods Receipt")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.PurchaseRequisition} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Purchase Requisition")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Contract} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Contract")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Budget} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Budget")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Reports} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Reports")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Timesheet} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Timesheet")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.CNReceived} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("CN Received")}</label>
                    </div>
                </div>

                <div className={`my-10`}>
                    <label className='text-secondary text-xl lg:text-2xl'>
                        {TextValue("Account Receivable")}
                    </label>
                </div>
                <div className='grid lg:grid-cols-4 grid-cols-2 md:grid-cols-3 w-full px-12 gap-8 justify-items-center'>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Invoice} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Invoice")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Quotation} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Quotation")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.StatementOfAccount} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Statement Of Account")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.DeliveryOrder} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Delivery Order")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.POReceived} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("PO Received")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.CreditNote} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Credit Note")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.GRNReceived} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("GRN Received")}</label>
                    </div>
                    <div className='flex flex-col lg:w-48 min-w-min items-center text-center gap-y-2'>
                        <div className='flex bg-primary p-0.5 rounded-lg'>
                            <Icon type={IconType.Notification} />
                        </div>
                        <label className='font-semibold text-lg lg:text-xl'>{TextValue("Notification")}</label>
                    </div>
                </div>
            </section> */}
        <section className="lg:grid lg:grid-cols-2 text-white bg-darkBlue w-full justify-center pt-12 lg:pl-24 pl-12 md:pl-16 space-y-12 gap-x-32 items-center">
          <div className="flex md:w-3/4 gap-y-4 flex-col">
            <label className="font-black text-3xl lg:text-4xl block w-9/12">Start your journey with us now</label>
            {/* <label className="font-normal text-sm text-secondary">
            Are you ready to make your business more organized?
          </label> */}
            <div>
              <button
                onClick={() => {
                  handleClick();
                }}
                type="button"
                className="font-medium text-white bg-primary rounded-lg hover:ring-0 hover:outline-none hover:bg-opacity-90 active:bg-opacity-70 font-medium rounded-xl text-sm px-4 py-2 text-center mr-3 md:mr-0 "
              >
                Call for a free demo
              </button>
            </div>
          </div>
          <div className=" flex pl-28 mb-0 md:sticky md:right-0 md:min-w-lg">
            <img src={Content} className=" mb-0" />
          </div>
        </section>
      </div>
    </>
  );
  return endDate > currentDate ? (
    <HomeLayout
      isLoading={false}
      showHeader={true}
      showFooter={false}
      mainContent={mainContentDesktopForTiming}
      currentPage={"Timing"}
    />
  ) : (
    <HomeLayout showHeader={true} showFooter={true} mainContent={mainContentDesktop} currentPage="Home" />
  );
}
