export const colors = {
  primary: '#00B14F',
  secondary: '#F15A29',
  tertiary: '#2862E1',
  colour_3: "--color_3",
  colour_4: "--color_4",
  font_primary: "primary",
  font_secondary: "--font_secondary",
  font_button: "--font_button",
  background: "--background",
  background_secondary: "--background_secondary",
  warning: "--warning",
  cancel: "--cancel",
  white: "--white",
  ratingStar: '#FFD700',
  ratingStarEmpty: '#C0C0C0',
  black: "#000",
  grayLight: '#F5F5F5',
  grayMedium: '#E0E0E0',
  grayDark: '#9E9E9E',
  blueLarge: "#181fa1",
  blueMedium: "#575de8",
  pinkLarge: "#FF1493"
};
